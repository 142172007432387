/**
* Hero
* A component should render a little piece of UI.
* It should not call any api, but should hold UI logic.
* @class Hero
* @param {object} props - Props
*/

import React from "react";
import Parser from 'html-react-parser';
import Button from '../../atoms/button'


class Hero extends React.Component {
	render() {
		const { type, video, videoUrl, videoCta, pretitle, image, imageMobile, title, children, callToAction } = this.props;
		return (
			<div
				className={`hero ${type} ${video === true ? 'hasVideo' : null}`}
			>
				{image ?
					<>
						<div
							className="hero__image"
							style={{
								backgroundImage: `url('${image}')`
							}}
						></div>
						<div
							className="hero__image hero__image--mobile"
							style={{
								backgroundImage: `url('${imageMobile ? imageMobile : image}')`
							}}
						></div>
					</>

					: null}

				<div className="hero__content">
					{pretitle ? <p className="hero__pretitle">{pretitle}</p> : null}
					<h1 className="hero__title">{title}</h1>
					<div className="hero__text">
						{typeof children === 'object' ? children : null}
						{typeof children === 'string' ? Parser(children) : null}
					</div>
					<div className="hero__cta">
						{
							callToAction ?
								<Button
									className="primary"
									color={'blue'}
									href={callToAction.link}
									label={callToAction.label}
									target={callToAction.target}
								/>
								: null

						}

						{
							video === true ?
								(
									<Button
										className="primary video"
										color={'blue'}
										label={videoCta}
										type={'video'}
										videourl={videoUrl}
									/>
								)
								: null
						}
					</div>
				</div>
			</div>
		);
	}
}
export default Hero;
