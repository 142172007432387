/**
 * Info
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Info
 * @param {object} props - Props
 */

import React from "react";
import Accordion from "../../components/accordion";
import Button from "../../atoms/button";
import EditorialIntro from "../../components/editorial";
import Parser from "html-react-parser";

const Info = ({
	preTitle,
	title,
	description,
	link,
	items,
	introTitle,
	introText,
	introLabel,
	introLink

}) => {

	return (

		<div className="container">
			{
				introTitle || introText ?
					<EditorialIntro>
						<div className="editorial-intro">
							{introTitle ? <h2>{introTitle}</h2> : null}
							<div className="p">{introText ? Parser(introText) : null}</div>
							{introLabel && <Button label={introLabel} className="primary" color="blue" link={introLink} />}
						</div>
					</EditorialIntro>

					: null
			}
			<div className="info-container">
				<div className="info-container__primary">
					<p className="info-container__preTitle">{preTitle}</p>
					<p className="info-container__title">{title}</p>
					<p className="info-container__description">{description}</p>
					<Button
						label="Scopri di più"
						className="primary sm _15 info-container__button"
						color="blue"
					/>
				</div>
				<div className="info-container__children">
					<Accordion items={items} />
				</div>
			</div>
		</div>
	);
}

export default Info;

