/**
 * HeroCarousel
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class HeroCarousel
 * @param {object} props - Props
 */

import React, { Fragment } from "react";
import Hero from '../../components/hero';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper';

SwiperCore.use([Navigation, Pagination, EffectFade]);

class HeroCarousel extends React.Component {

	render() {
		const { slides } = this.props;

		return (
			<div className={"heroCarousel"}>
				{
					slides.length > 1 ?
						<Fragment>
							<div className="container">
								<div className="heroCarousel__navigation-next"><span className="btn next"></span></div>
								<div className="heroCarousel__navigation-prev"><span className="btn prev"></span></div>
							</div>
							<div className="container">
								<div className="heroCarousel__pagination">
									<div className="bullets"></div>
								</div>
							</div>
							<Swiper
								pagination={{
									clickable: true,
									el: '.heroCarousel__pagination .bullets',
									type: 'bullets',
								}}
								autoHeight={true}
								navigation={{
									nextEl: '.heroCarousel__navigation-next .btn',
									prevEl: '.heroCarousel__navigation-prev .btn',
								}}
								effect='fade'
								fadeEffect={{
									crossFade: true
								}}
							>

								{
									slides.map((slide, idx) => (
										<SwiperSlide key={idx}>
											<Hero {...slide}>
												{slide.children}
											</Hero>
										</SwiperSlide>
									))}
							</Swiper>
						</Fragment>

						: <Fragment>
							<Hero {...slides[0]}>
								{slides[0].children}
							</Hero>
						</Fragment>
				}
			</div >
		);
	}

}

export default HeroCarousel;

