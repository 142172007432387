/**
 * Push
 * A module is meant to be rendered by pages
 * (maybe wrapped into one or more Layouts)
 * A module should render components, passinng data to them.
 * @class Push
 * @param {object} props - Props
 */

import React from "react";
import Button from '../../atoms/button'
import EditorialIntro from "../../components/editorial"
import Parser from "html-react-parser";

class Push extends React.Component {

	render() {
		const { image, color, layout, pretitle, title, text, callToAction, introTitle, introText, introLabel, introLink } = this.props;
		return (
			<>
				{
					introTitle || introText ?
						<div className={'container'}>
							<EditorialIntro>
								<div className="editorial-intro">
									{introTitle ? <h2>{introTitle}</h2> : null}
									<div className="p">{introText ? Parser(introText) : null}</div>
									{introLabel && <Button label={introLabel} className="primary" color="blue" link={introLink} />}
								</div>
							</EditorialIntro>
						</div>

						: null
				}

				<div className={`editorialPush editorialPush--${color} editorialPush--${layout}`}>
					<div className="editorialPush__content">
						<div className="inner">
							{
								pretitle ? <div className="editorialPush__pre-title">{pretitle}</div> : null
							}
							{
								title ? <div className="editorialPush__title">{title}</div> : null
							}
							{
								text ? <div className="editorialPush__text">{text}</div> : null
							}
							<div className="editorialPush__cta">
								<Button
									className="secondary"
									color={color === 'white' ? 'blue' : 'white'}
									href={callToAction.link}
									label={callToAction.label}
									target={callToAction.target}
								/>
							</div>
						</div>
					</div>
					<div className="editorialPush__image" style={{
						backgroundImage: `url("${image}")`
					}}></div>
				</div>
			</>
		);
	}

}

export default Push;

