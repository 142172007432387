/**
 * Accordion
 * A component should render a little piece of UI.
 * It should not call any api, but should hold UI logic.
 * @class Accordion
 * @param {object} props - Props
 */

import React, { useState } from "react";


const Accordion = ({ items = [], ...other }) => {

    const updateImage = idx => {
        setActiveIndex(idx)
    }

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="accordion">
            <div className="accordion__items">
                {items.length > 1 &&
                    items.map(({ title, description, image, link }, idx) => (
                        <div key={idx} className={`accordion__item ${idx === activeIndex ? 'accordion--active' : ''}`}>
                            <div className="accordion__content">
                                <div className="accordion__header">
                                    <button type="button" onClick={event => updateImage(idx)}>{title}</button>
                                </div>
                                <div className="accordion__body">
                                    {description}
                                    <div
                                        className="accordion__image accordion__image--mobile"
                                    >
                                        <span style={{ backgroundImage: `url(${image})` }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <div
                className="accordion__image"
            >
                {items.length > 1 &&
                    items.map(({ title, description, image, link }, idx) => (
                        <span key={idx} className={`${activeIndex === idx ? 'active' : ''}`} style={{ backgroundImage: `url(${image})` }}></span>
                    ))}
            </div>
        </div>
    );
}



export default Accordion;
